<template>
    <div class="young-lady-container">
        <!-- empty -->
        <div class="empty" :style="{ 'height': `${emptyHeight}px` }"></div>
        <!-- content text -->
        <Row class="text" type="flex" justify="center" align="middle">
            <Col :span="22" :md="18" :lg="18">
                <p>Young ladies are innovative and do not mind experimenting with fashion or what is sure to come in the near future you are adventurous. When young girls are adventurous people may call their clothes outlandish, but girls do not care. Young ladies know this will be the norm soon enough.</p>
            </Col>
        </Row>
        <!-- <div class="text">
            <p>Young ladies are innovative and do not mind experimenting with fashion or what is sure to come in the near future you are adventurous. When young girls are adventurous people may call their clothes outlandish, but girls do not care. Young ladies know this will be the norm soon enough.</p>
        </div> -->
        <!-- content -->
        <Row class="young-lady" type="flex" justify="center" align="middle">
            <Col :span="22" :md="18" :lg="18">
                <ul class="waterfall"> 
                    <li v-for="item in 6" :key="item" class="item">
                        <img :src="require(`@img/young-lady/y-${item}.jpg`)">
                    </li> 
                </ul>
            </Col>
        </Row>
        <div class="footer">
            <img :src="require(`@img/young-lady/footer.jpg`)" alt="">
            <copy-right />
        </div>
    </div>
</template>

<script>
import common from '@M/common';
import CopyRight from '@C/CopyRight';

export default {
    name: 'YoungLady',
    mixins: [common],
    components: {
        CopyRight
    },
    data() {
        return {}
    },
    created() {
        this.setCoverImage(require('@img/young-lady/banner.jpg'));
    },
    mounted() {
        this.addCoverResize();
    },
    beforeDestroy() {
        this.removeCoverResize();
    }
}
</script>

<style lang="scss">
.young-lady-container .text {
    word-break: break-all;
    background: #2F2F2D;
    color: #fff;
    height: 350px;
    // padding: 5rem 3rem;

    p {
        line-height: 2;
        word-break: break-word;
    }
}
.young-lady {
    background: #fff;
    padding: 1rem 0 6rem 0;
    .waterfall { 
        column-count: 2;
        column-gap: 0px;
        .item {
            img {
                width: 100%;
                height: inherit;
                vertical-align: text-bottom;
            }
        }
    }

}
.footer {
    width: 100%;
    img {
        width: 100%;
        vertical-align: bottom;
    }
}
</style>